@import '@milliman-innersource/milliman-design-system/themes/scss/white/index.scss';

#filtersInModal, .reportPacketBuilder {
  .bx--label, .bx--toggle-input__label {
    font-weight: 700;
  }
  .continuouslyEnrolled {
    .bx--date-picker--range {
      width:100%;
      .bx--date-picker-container {
        width:50%;
      }
      .bx--date-picker__input {
        width:100%;
      }
    }
    .bx--col {
      padding-right:0;
    }
  }
  
}

.reportPacketBuilder{
  .bx--modal-footer{
    margin-left: 43%;
  }
  .saveView{
    #saveForLaterToggle + label {
      margin-top: -10px
    }

  }
  #selectMetricsView{
    .listItem{
      .infosign{
        margin-right: 10px;
        display: none;
      }
    }

    .listItem:hover{
      background-color: rgba(196, 196, 196, 0.5) ;
      .infosign{
        display: block;
      }


    }

  }

}
.savedMetrics{
  .bx--modal-footer{
    margin-left: 43%;
  }
  .bx--modal-content{
    margin-bottom: 0px;
    overflow: hidden;
  }

  #searchByName{
  width: 74%;
  position: relative;
  input {
    border: 1px solid #CCCCCC;
    background-color: #F8F8F8;
    padding-left: 15px;
    height: 35px;
    }
    svg{
      left: 90%;
    }
  }
  #myCustomizationCheckbox + label{
    margin-left: 54%;
  }

  .savedCustomizationTable{
    // table data
    tbody { 
      max-height: 320px;
      display: block;
      overflow: auto;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
  }

  thead{
    th{
      button{
        background-color: #0080E2;
        color: white;
        &:hover{
          background-color: #086ebb;
          svg{
            fill: white;
          }

        }
      }
    }
  }
  }

}

.bx--modal-container {
  height:50rem;
}

.titleRow {
  background-color: $ui-03;
}
.bx--modal-container {
  width: 1000px;
}
.bx--modal-container .bx--modal-header, .bx--modal-container .bx--modal-content, .bx--modal-container .bx--modal-content__regular-content {
  padding:1rem;
}

.bx--header, .bx--side-nav {
  background-color: $ui-05;
}

.selectedReports {
  background-color: $ui-03;
  height:100%;
}

a.bx--side-nav__link--current, .bx--side-nav__item--active {
  background-color: $active-primary;
}

.bx--side-nav__icon > svg {
  fill: white;
}
a.bx--side-nav__link > .bx--side-nav__link-text,.bx--side-nav__icon, .bx--side-nav__submenu-title {
  color: white;
  
}
a.bx--side-nav__link:hover svg path,li.bx--side-nav__item:hover svg path{
fill:black;
}

 .bx--side-nav__submenu-title:hover,.bx--side-nav__item .bx--side-nav__item--icon:hover{
  
  color: black;
}
 
.bx--data-table-header {
  background-color: white;
}
.bx--toggle-input:checked + .bx--toggle-input__label > .bx--toggle__switch::before {
  background-color: $active-primary;
}

.modalText {
  color: #727A7D;
  font-size:12px;
  text-align: center;
}
.faq {
  color: #727A7D;
  font-size:12px;
  padding: 8px;
}

.bx--progress {
  width: 40rem;
}

.bx--progress-step {
  min-width: 10rem !important;
  max-width: 12rem;
  .bx--progress-label {
    font-size: 12px;
    max-width: none;
  }
}

.storyboardTabs {
  button {
    text-align: center !important;
  }
}

.reportList ::before {
  content: "•   ";
  color: black;
  margin-right: 10px;
}

.relatedList >::before {
  content: "•   ";
  color: black;
  margin-right: 10px;
}

.tableCell{
  background-color: #FFF !important;
  .upTrendGreenSvg path:hover{
    stroke:#00A562;
    stroke-width: 3px;      
  }
  .downTrendRedSvg path:hover{
    stroke: #A5203F;
    stroke-width: 3px;    
  }
}

.tableCell{
  .downTrendSvg path:hover{
    stroke:#00A562;
    stroke-width: 3px;      
  }
  .upTrendSvg path:hover{
    stroke: #A5203F;
    stroke-width: 3px;    
  }
}

.bx--tooltip {
  padding: 1px;
  text-align: center;
}

.ComparisonDropDown button {
	background-color: white;
}

.ComparisonMeasureSearch input {
	background-color: white;
}

.NewCustomization button {
	background-color:#fdfdfd;
}

#metricsIndicator{
  padding-left: 21%;
  li {
    .bx--progress-label{
      max-width: 7rem
    }
  }
}

#customizeMetricsDropdown {
  div.bx--list-box__menu{
    width: 204px;
    left: -24px;
  }
}

.SpecialTopicBanner {
  height: 480px;
  color: white;
  padding-left:300px;
}

.bannerDescription {
  width: 830px;
  position: relative;
}

#DiabetesBanner {
  background-image: url("./images/DiabetesBanner.png");
  background-size: cover;
}

#COVIDBanner {
  background-image: url("./images/COVIDBanner.png");
  background-size: cover;
}

#TelehealthBanner {
  background-image: url("./images/TelehealthBanner.png");
  background-size: cover;
}

#HighCostClaimantsBanner {
  background-image: url("./images/HighCost\ ClaimantsBanner.png");
  background-size: cover;
}

#CompareContrastBanner {
  background-image: url("./images/CompareContrastBanner.png");
  background-size: cover;
}