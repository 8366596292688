#sidePanelFilter, #packetFiltersReview {
    padding: 0 10px;
    .lineSeparator {
      border: 1px solid #e8e9ea;
      width: 100%;
      height: 0px;
      margin: 10px 0;
    }
    #pageLevelFilters {
      padding-top: 15px;
      .bx--form-item {
        padding-top: 18px;
      }
      .bx--select-input{
        border-bottom: 0px;
      }
    }
    #refreshPageFilter {
      float: right;
      padding-top: 25px;
      p {
        color: $active-primary;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        padding-left: 20px;
      }
    }
    .labelHeading {
      color: #616771;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 20px;
    }
    .labelSubHeading {
      color: #616771;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
    .labelText {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      padding-bottom: 20px;
    }
    .labelSubText {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      padding-bottom: 20px;
      padding-left: 15px;
    }
    .labelDateTimeText{
      float: right;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-right: 0px;
    }
    .labelDateTimeTextExtend {
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0.16px;
      color: #616771;
      margin-right: 20px;
    }
    .bx--tooltip__label {
      float: right;
      padding-top: 1px;
    }
}