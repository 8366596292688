.HeadColumnContainer1{
    padding: 20px;
    background-color: #FFF;
    margin-right: 10px;
    height: 330px;
    h5{
        margin-left: 0px;
    }
    .Row1{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-top: 5px;
            font-size: 40px;
        }
    }
    .Row2{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5rem;
        p{
            text-align: center;
            font-size: 12px;
            width: 55%;
        }
    }
    .Row3{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        div{
            width: 100px;
            height: 1px;
            background-color: #E8E9EA;
        }
    }
    .Row4{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-top: 5px;
            font-size: 28px;
        }
    }
    .Row5{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            text-align: center;
            font-size: 12px;
        }
    }
    .Row6{
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            width: 100px;
            height: 1px;
            background-color: #E8E9EA;
        }
    }
}
.HeadColumnContainer2{
    padding: 20px;
    background-color: #FFF;
    margin-right: 10px;
    height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
    h5{
        margin-left: 0px;
    }
    .Row21{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-top: 5px;
            font-size: 48px;
        }
    }
    .Row22{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            text-align: center;
            font-size: 12px;
            width: 45%;
        }
    }
    .Row23{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        div{
            width: 100px;
            height: 1px;
            background-color: #E8E9EA;
            margin-top: 10px;
        }
    }
    .Row24{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin-top: 5px;
            font-size: 36px;
        }
    }
    .Row25{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            text-align: center;
            font-size: 12px;
        }
    }
}
.Covid19DashboardColumnContainer{
    .OverviewBoxContainer{
        margin-left: 0px;
    }
    .CovidMapContainer{
        margin-top: 30px;
        margin-left: 0px;
        .CovidMapColumnContainer{
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 2.1rem;
            padding-right: 0px;
            background-color: #FFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            .Covid19MapHeadingContainer{
                p{
                    font-weight: 600;
                    font-size: 16px;
                }
            }
            .Covid19MapContainer{
                margin-top: 1rem;
                width: 100%;
                div{
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    .ConfirmedCovidCasesContainer{
        padding-top: 30px;
        margin-left: 0px;
        .Covid19CasesContainer{
            padding: 15px;
            background-color: #FFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            .Covid19CaseRow1{
                margin-left: 0px;
                p{
                    font-weight: 600;
                    font-size: 16px;
                }
            }
            .Covid19CaseRow2{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    margin-top: 5px;
                    font-size: 72px;
                }
            }
            .Covid19CaseRow3{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    width: 150px;
                }
            }
            .Covid19CaseRow4{
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                div{
                    margin-top: 25px;
                    width: 100px;
                    height: 1px;
                    background-color: #E8E9EA;
                }
            }
            .Covid19CaseRow5{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    margin-top: 15px;
                    font-size: 36px;
                }
            }
            .Covid19CaseRow6{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    font-size: 12px;
                }
            }
        }
        .DiagnosedInpatientContainer{
            background-color: #FFF;
            margin-left: 20px;
            margin-right: 10px;
            justify-content: center;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            /* div{
                background-color: white;
                padding: 20px 10px 20px 10px;
                height: 100%;
            }  */
        }
        .PopulationCovidRiskContainer{
            background-color: #FFF;
            margin-left: 10px;
            margin-right: 20px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            /* div{
                background-color: white;
                padding: 20px 10px 20px 10px;
                height: 100%;
            }  */
        }
        .ConnectedUtillContainer{
            padding: 15px;
            background-color: #FFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            .ConnectedUtillRow1{
                margin-left: 0px;
            }
            .ConnectedUtillRow2{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    margin-top: 5px;
                    font-size: 72px;
                }
            }
            .ConnectedUtillRow3{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    width: 150px;
                }
            }
            .ConnectedUtillRow4{
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                div{
                    margin-top: 25px;
                    width:100px;
                    height: 1px;
                    background-color: #E8E9EA;
                }
            }
            .ConnectedUtillRow5{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    margin-top: 15px;
                    font-size: 36px;
                }
            }
            .ConnectedUtillRow6{
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    font-size: 12px;
                }
            }
        }
    }
    .Covid19ExecutiveContainer{
        margin-top: 30px;
        margin-left: 0px;
        .Covid19ExecutiveHeading{
            p{
                font-size: 20px;
            }
        }
    }
    .KeyIndicatorsHeading{
        margin-top: 30px;
        margin-left: 0px;
    }
    .KeyIndicatorContainer{
        margin-top: 10px;
        margin-left: 0px;
        .KeyIndicatorColumn1{
            padding: 0px 0px 0px 0px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
            /* div{
                background-color: white;
                padding: 20px;
                display: flex;
            } */
        }
    }
}
