#inpatientDrillModal{
    .bx--modal-header{
      padding-top: 1.5rem;
      h3{
        font-style: italic;
      }
    }
    #inpatientDrillModalDropdown{
      border-bottom: none;
      .bx--list-box__menu{
        max-height: 18.75rem;
      }
    }
  
    .bx--modal-footer{
      button {
        margin-left: 84%;
        margin-top: 6px;
      }
    }
  }