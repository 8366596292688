
.KeyMetricContainer {
    margin-Top: 15px;
    
    .title {
        display: flex;
        align-items: center;
        height: 155px;
        margin-right: 20px;
        justify-content: flex-end;
    }

    div:nth-child(2) {
        padding-right: 10%;

        .KeyMetric {
            width: 100%;
            height: auto;
            background-color: #FFFFFF;
            padding: 25px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
    }
}
