#frequentFliersDrillModal{
    top: 3.8%;
    left: 1.2%;
    background-color: transparent;
    .bx--modal-container{
      width: 97%;
      .bx--modal-header{
        padding-bottom: 30px;
      }
      .bx--modal-close{
        margin: 18px 45px 0px 7px;
        padding: 0px;
        z-index: 2;
        svg{
          width: 3rem;
          height: 3rem;
          fill: #727A7D;
        }
      }
    }
    .bx--modal-footer{
      display: none;
    }
  
    .bx--modal-content{
      padding-top: 0px;
    }
  }