.bx--pagination {
    background-color: #FFFFFF;
}
.HeadColumnContainer{
    padding-left: 34px;
    padding-bottom: 200px;
    .HeadRowContainer{
        margin-left: 0px;
        
        .columnContainer{
            h1{
                color: #39414D;
            }
            p{
                margin-top: 10px;
                width:832px;
            }
            li{
                padding: 4px;
                color: #0080E2;
                a{
                    text-decoration: none;
                }
            }
            .SubRowContainer{
                margin-top: 20px;
                width: 900px;
                font-size: 16px;
                .List{
                    padding: 0px;
                }
            }
        }
    }
    .HighCostHeading{
        margin-top: 70px;
        margin-left: 0px;
    }
    .HighCostTable{
        margin-left: 0px;
        .HighCostTableHeader{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .HighCostTableTitle{
                display: flex;
                justify-content: space-between;
                height: 1.5rem;
                p{
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }

}

.HighCostTableBody{
    background-color: #EFF0F1
}
.ArrowDown{
    background-color: #EFF0F1;
    cursor: pointer;
    div{
        display: flex;
        margin-right: 10px;
        margin-bottom: 1px;
    }
}
.ArrowUpInside{
    // fill: pink;
    visibility: hidden;
    
}
.ArrowDownInside{
    //fill: pink;

    visibility: hidden;
   
}
.ArrowDown:hover{
    .ArrowDownInside{
        
        visibility: visible;
    }
    
}
.ArrowDown:hover{
    .ArrowUpInside{
        visibility: visible;
    }
}
.ExploreRelatedTopic{
    padding-top: 78px;
    font-size: 42px;
}
.storyboardContainer{
    padding-top: 32px;
    font-size: 16px;
    a{
       text-decoration: none;
    }
}
.HighCast{
    background-color: #fff;
    width: 100%;
}
.HighCastTableContainer{
    thead tr{
        th:nth-child(9n){
            div{
                width:100px;
            }
        }
        th:nth-child(14n){
                div{
                    width:320px;
                }
               
        }
    }
    thead {
        position: sticky;
        top: 0;
    }
    background-color: #fff;
    flex-grow: 1;
    width: 100%;
}
/* 
.HighCastTableContainer{
    display: block;
}
.fixedHeight{
    display: block;
}
.fixedHeight{
    height: 400px;
    overflow-y: auto;
}
 */
 /* .HighCastTableContainer {
    .bx--data-table-content{
        overflow-y: auto;
        max-height: 510px;
    }

 }*/
 /* .HighCastTableContainer thead {
    position: sticky;
    top: 0;
  } */
   .HighCast,
   .HighCastTableContainer {
    .bx--data-table-content{
        max-height: 480px;
    }
   }
   .HighCastTableContainer{
        overflow-y: hidden;
    
   }
   .HighCastTableContainer {
    .bx--data-table-content{
        left: 0;
     overflow-x: scroll;
     overflow-y: scroll;
    }
   }
   .HighCastTableContainer {
    .bx--data-table-content::-webkit-scrollbar{
        display: none; //for chorme ,opera,safari
    }
     //display: none; //for chorme ,opera,safari
   }
   .HighCastTableContainer {
    .bx--data-table-content{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  /*   -ms-overflow-style: none;  /* IE and Edge */
   // scrollbar-width: none;  /* Firefox */ */
  }